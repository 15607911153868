import React from 'react'
import { graphql, withPrefix, Link } from 'gatsby'
import styled, { css, keyframes } from 'styled-components'
import { connect } from 'react-redux'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import InViewMonitor from 'react-inview-monitor'

import SEO from '../components/seo'

import * as Layout from '../layouts/layout'
import * as FadeIn from '../components/fadeIn'

const API_PATH = 'https://api.tryhard.me/fuyoen/recruit/index.php'

class Recruit extends React.Component {
  state = {
    name: '',
    furigana: '',
    tel: '',
    email: '',
    birthYear: '',
    birthMonth: '',
    birthDay: '',
    mailSent: false,
    error: null,
  }

  handleFormSubmit = e => {
    e.preventDefault()
    axios({
      method: 'post',
      url: `${API_PATH}`,
      headers: { 'content-type': 'application/json' },
      data: this.state,
    })
      .then(result => {
        this.setState({
          mailSent: result.data.sent,
        })
      })
      .catch(error => this.setState({ error: error.message }))
  }

  render() {
    const lang = this.props.lang
    return (
      <>
        <SEO title="Recruit" />
        <Hero className="hero">
          <HeroHeader>
            <FadeIn.Left margin="0%">
              <h1>
                {lang === 'ja' && 'スタッフ募集'}
                {lang === 'en' &&
                  'I want to convey the true taste of authentic Sichuan cuisine'}
                {lang === 'zh' && '招募店员'}
              </h1>
            </FadeIn.Left>

            <FadeIn.Left margin="0%">
              <p>
                {lang === 'ja' && '本物の四川料理の美味しさをお客様に提供する'}
                {lang === 'en' &&
                  'I want to convey the true taste of authentic Sichuan cuisine'}
                {lang === 'zh' && '为客人提供地道的四川料理的美味'}
              </p>
            </FadeIn.Left>
          </HeroHeader>
        </Hero>
        <Grid>
          <GridBodyAbove>
            <GridBodyAboveTxt>
              <GridBodyAboveTxtBox>
                <FadeIn.Left>
                  <h3>
                    {this.props.lang === 'ja' &&
                      '飲食というツールを通じて四川の豊かな文化を発信する'}
                    {this.props.lang === 'en' &&
                      'I want to convey the true taste of authentic Sichuan cuisine'}
                    {this.props.lang === 'zh' && '通过饮食传播四川丰富的文化'}
                  </h3>
                </FadeIn.Left>

                <FadeIn.Left>
                  <p>
                    {this.props.lang === 'ja' &&
                      'トライハードダイニングは、TryHard Groupの一員として、四川料理の「文化」を大切に、お客様に「笑顔」を添えて「正真正銘、本物の四川料理の美味しさをお客様に提供する」をテーマに本場四川から特級料理人を招聘し、本格四川料理店を現在8店舗展開しております。'}
                    {this.props.lang === 'en' &&
                      'Sichuan cuisine, a food culture with a history of over 2300 years, is one of the four major Chinese dishes. The food culture of the ancient tombs that appear in "Haoyang Kunishi" written in 316 BC, is said to be "The name surpasses Sanuki Sanri and the taste overwhelms the province of Gangnam".'}
                    {this.props.lang === 'zh' &&
                      'TryHard餐厅，作为TryHard Group的一员，重视四川料理的“文化”，向客人提供亲切的“笑容”，以“地地道道，将真正的四川料理的美味提供给客人”为主题，从正宗的四川聘请了特级厨师，现在有5家正宗的四川料理店。'}
                  </p>
                </FadeIn.Left>
              </GridBodyAboveTxtBox>
            </GridBodyAboveTxt>
            <GridBodyAboveImg>
              <img src={withPrefix('/img/recruit-dining.jpg')} alt="hero" />
              <InViewMonitor
                childPropsInView={{ isActive: true }}
                intoViewMargin="50%"
              >
                <GridBodyAboveImgWrapper />
              </InViewMonitor>
            </GridBodyAboveImg>
          </GridBodyAbove>
          <GridBodyBelow>
            <GridBodyBelowBox>
              <GridBodyBelowTxt>
                <FadeIn.Right>
                  <InViewMonitor childPropsInView={{ isActive: true }}>
                    <GridBodyBelowH2>TryHard DINING</GridBodyBelowH2>
                  </InViewMonitor>
                </FadeIn.Right>

                <FadeIn.Right>
                  <h3>
                    {this.props.lang === 'ja' && '芙蓉苑のこだわり'}
                    {this.props.lang === 'en' && 'Perseverance'}
                    {this.props.lang === 'zh' && '芙蓉苑的观念'}
                  </h3>
                </FadeIn.Right>

                <FadeIn.Right>
                  <p>
                    {this.props.lang === 'ja' &&
                      '京都をはじめ関西圏を中心に店舗拡大を続けております。また食博、肉フェス等の食イベントにも積極的に参加、飲食というツールを通じて四川の豊かな文化を発信しております。'}
                    {this.props.lang === 'en' &&
                      'We have a mission to convey the taste of authentic Sichuan cuisine to Japan, cherishing the food culture of authentic Sichuan. We promise to deliver to customers with "Smiles" carefully, keeping the taste of authentic "Sichuan cuisine" authentic.'}
                    {this.props.lang === 'zh' &&
                      '从京都开始围绕关西圈为中心正在持续扩大店铺。另外，也积极参加食物博览展、肉盛典等饮食活动，通过饮食传播四川丰富的文化。'}
                  </p>
                </FadeIn.Right>

                <Buttons>
                  <FadeIn.Up>
                    <Button href="#form">
                      <p>
                        {this.props.lang === 'ja' && '今すぐ応募する'}
                        {this.props.lang === 'en' && 'Look at sticking'}
                        {this.props.lang === 'zh' && '现在立即应聘'}
                      </p>
                    </Button>
                  </FadeIn.Up>
                </Buttons>
              </GridBodyBelowTxt>
            </GridBodyBelowBox>
          </GridBodyBelow>
        </Grid>
        <Layout.Section>
          <Layout.Container>
            <InfoHeader>
              <FadeIn.Left>
                <h2>
                  {this.props.lang === 'ja' && '芙蓉苑グループ店舗情報'}
                  {this.props.lang === 'en' && 'Look at sticking'}
                  {this.props.lang === 'zh' && '芙蓉苑集团店铺信息'}
                </h2>
              </FadeIn.Left>

              <FadeIn.Left>
                <p>
                  {this.props.lang === 'ja' &&
                    '2015年12月に本店「四川料理 芙蓉苑」をオープン。6年で8店舗展開しております。'}
                  {this.props.lang === 'zh' &&
                    '2015年12月、本店「四川料理 芙蓉苑」开业。6年内开了8家店铺。'}
                </p>
              </FadeIn.Left>
            </InfoHeader>
            <InfoBox>
              <InfoDate>
                {this.props.lang === 'ja' && '2015年12月'}
                {this.props.lang === 'zh' && '2015年12月'}
              </InfoDate>
              <InfoBody>
                {this.props.lang === 'ja' &&
                  '長堀橋に四川料理 芙蓉苑 長堀橋本店オープン'}
                {this.props.lang === 'zh' && '長堀橋四川料理 芙蓉苑本店开业'}
              </InfoBody>
            </InfoBox>
            <InfoBox>
              <InfoDate>
                {this.props.lang === 'ja' && '2017年3月'}
                {this.props.lang === 'zh' && '2017年3月'}
              </InfoDate>
              <InfoBody>
                {this.props.lang === 'ja' &&
                  '十三に四川料理 芙蓉麻婆麺 十三店オープン'}
                {this.props.lang === 'zh' &&
                  '十三四川料理 芙蓉麻婆麺 十三店开业'}
              </InfoBody>
            </InfoBox>
            <InfoBox>
              <InfoDate>
                {this.props.lang === 'ja' && '2017年10月'}
                {this.props.lang === 'zh' && '2017年10月 '}
              </InfoDate>
              <InfoBody>
                {this.props.lang === 'ja' &&
                  '芙蓉苑 長堀橋本店2Fに、芙蓉火鍋城 長堀橋店オープン'}
                {this.props.lang === 'zh' &&
                  '長堀橋、本店２楼芙蓉苑 火鍋城 長堀橋店开业'}
              </InfoBody>
            </InfoBox>
            <InfoBox>
              <InfoDate>
                {this.props.lang === 'ja' && '2019年6月 '}
                {this.props.lang === 'zh' && '2019年6月  '}
              </InfoDate>
              <InfoBody>
                {this.props.lang === 'ja' &&
                  '京都木屋町通に芙蓉火鍋城 京都店オープン'}
                {this.props.lang === 'zh' &&
                  '京都木屋町芙蓉苑 火鍋城 京都店开业'}
              </InfoBody>
            </InfoBox>
            <InfoBox>
              <InfoDate>
                {this.props.lang === 'ja' && '2020年7月 '}
                {this.props.lang === 'zh' && '2020年7月  '}
              </InfoDate>
              <InfoBody>
                {this.props.lang === 'ja' &&
                  'FUYOEN 泉南りんくう公園店オープン'}
                {this.props.lang === 'zh' &&
                  'FUYOEN 泉南りんくう公園店オープン'}
              </InfoBody>
            </InfoBox>
            <InfoBox>
              <InfoDate>
                {this.props.lang === 'ja' && '2021年3月 '}
                {this.props.lang === 'zh' && '2021年3月  '}
              </InfoDate>
              <InfoBody>
                {this.props.lang === 'ja' &&
                  'FUYOEN 心斎橋PARCO店オープン'}
                {this.props.lang === 'zh' &&
                  'FUYOEN 心斎橋PARCO店オープン'}
              </InfoBody>
            </InfoBox>
            <InfoBox>
              <InfoDate>
                {this.props.lang === 'ja' && '2021年9月 '}
                {this.props.lang === 'zh' && '2021年9月  '}
              </InfoDate>
              <InfoBody>
                {this.props.lang === 'ja' &&
                  '狐食堂 韓国料理 Someck & FUYOENエンタメ酒場 京都店オープン'}
                {this.props.lang === 'zh' &&
                  '狐食堂 韓国料理 Someck & FUYOENエンタメ酒場 京都店オープン'}
              </InfoBody>
            </InfoBox>
            <InfoBox>
              <InfoDate>
                {this.props.lang === 'ja' && '2021年11月 '}
                {this.props.lang === 'zh' && '2021年11月  '}
              </InfoDate>
              <InfoBody>
                {this.props.lang === 'ja' &&
                  '芙蓉苑 渋谷PARCO店 オープン'}
                {this.props.lang === 'zh' &&
                  '芙蓉苑 渋谷PARCO店 オープン'}
              </InfoBody>
            </InfoBox>
          </Layout.Container>
        </Layout.Section>
        <Layout.Section>
          <Layout.Container>
            <ChallengeSubHeader>
              <FadeIn.Left>
                <h3>
                  {this.props.lang === 'ja' && '求める人物像'}
                  {this.props.lang === 'en' && 'Look at sticking'}
                  {this.props.lang === 'zh' && '求人要求'}
                </h3>
              </FadeIn.Left>

              <FadeIn.Left>
                <p>
                  {this.props.lang === 'ja' &&
                    '私たちの目指すビジョン、理念に共感いただける方を求めています。'}
                  {this.props.lang === 'en' && 'Look at sticking'}
                  {this.props.lang === 'zh' &&
                    '我们需要和我们的目标，理念有同感，能一起前进的人。'}
                </p>
              </FadeIn.Left>
            </ChallengeSubHeader>
            <ChallengeHeader>
              <FadeIn.Left>
                <h2>Challenge</h2>
              </FadeIn.Left>

              <FadeIn.Left>
                <p>
                  {this.props.lang === 'ja' &&
                    '失敗を恐れることなくチャレンジする姿勢'}
                  {this.props.lang === 'en' && 'Look at sticking'}
                  {this.props.lang === 'zh' && '不惧怕失败也可以挑战的姿态'}
                </p>
              </FadeIn.Left>
            </ChallengeHeader>
            <Layout.Row style={{ margin: 0 }}>
              <ChallengeBox>
                {this.props.lang === 'ja' &&
                  '「お客様は何を求めているのか？」という強い顧客志向を持ち、「我々はどのような価値を提供できるのか？」を常に意識しながら、失敗を恐れることなくチャレンジできる方です。お客様や共に働く仲間に対して笑顔を添えることができる方。また、お客様目線に立って自らの頭で考え、不器用でもひたむきに取り組む姿勢を評価いたします。'}
                {this.props.lang === 'en' && 'Look at sticking'}
                {this.props.lang === 'zh' &&
                  '“客户想要什么？“我们有很强的顾客意识，”我们能提供什么样的价值？“要时常想的同时，既不惧怕失败也可以挑战的人。能对客人和一起共同工作的伙伴露出笑容的人。另外，站在客人的角度上，用自己的头脑思考，即使做法笨拙也会一心一意用尽全力。'}
              </ChallengeBox>
            </Layout.Row>
          </Layout.Container>
        </Layout.Section>
        <Layout.Section>
          <Layout.Container>
            <RequirementsHeader>
              <FadeIn.Left>
                <h2>Requirements</h2>
              </FadeIn.Left>

              <FadeIn.Left>
                <p>
                  {this.props.lang === 'ja' && '募集要項'}
                  {this.props.lang === 'en' && 'Look at sticking'}
                  {this.props.lang === 'zh' && '招募条件'}
                </p>
              </FadeIn.Left>
            </RequirementsHeader>
            <SmallGrid>
              <SmallGridBelowRight>
                <img src={withPrefix('/img/recruit-hole.jpg')} alt="" />
                <InViewMonitor
                  childPropsInView={{ isActive: true }}
                  intoViewMargin="50%"
                >
                  <SmallGridBelowWrapper />
                </InViewMonitor>
              </SmallGridBelowRight>
              <SmallGridAboveLeft>
                <FadeIn.Left>
                  <h3>
                    {this.props.lang === 'ja' && 'ホールサービス職'}
                    {this.props.lang === 'en' && 'Look at sticking'}
                    {this.props.lang === 'zh' && '大厅服务类'}
                  </h3>
                </FadeIn.Left>

                <FadeIn.Left>
                  <p>
                    {this.props.lang === 'ja' &&
                      '接客を通じて、お客様が笑顔になっていただけるよう考え行動いただきます。様々な経験を積んでいただきながら、後輩への指導、管理業務、開発業務にも携わっていただきます。店長となれば、人・物・金に関する店舗全体をマネジメントいただくことになります。'}
                    {this.props.lang === 'en' && 'Look at sticking'}
                    {this.props.lang === 'zh' &&
                      '通过接待客人，做出让客人满足的行动.在积累了各种各样的经验的同时，也参与了对后辈的指导、管理业务、开发业务。店长的话，需要管理人、物、金钱相关的店铺整体业务。'}
                  </p>
                </FadeIn.Left>
              </SmallGridAboveLeft>
            </SmallGrid>
            <SmallGrid>
              <SmallGridBelowLeft>
                <img src={withPrefix('/img/recruit-cooking.jpg')} alt="" />
                <InViewMonitor
                  childPropsInView={{ isActive: true }}
                  intoViewMargin="50%"
                >
                  <SmallGridBelowWrapper />
                </InViewMonitor>
              </SmallGridBelowLeft>
              <SmallGridAboveRight>
                <FadeIn.Right>
                  <h3>
                    {this.props.lang === 'ja' && '調理職'}
                    {this.props.lang === 'en' && 'Look at sticking'}
                    {this.props.lang === 'zh' && '料理类职'}
                  </h3>
                </FadeIn.Right>

                <FadeIn.Right>
                  <p>
                    {this.props.lang === 'ja' &&
                      '調理を通じて、お客様が笑顔になっていただけるよう考え行動いただきます。様々な経験を積んでいただきながら、後輩への指導、管理業務、開発業務にも携わっていただきます。料理長となれば、人・物・金に関する店舗全体をマネジメントいただくことになります。'}
                    {this.props.lang === 'en' && 'Look at sticking'}
                    {this.props.lang === 'zh' &&
                      '通过烹饪，能让客人露出笑容。在积累了各种各样的经验的同时，也参与了对后辈的指导、管理业务、开发业务。如果成为厨师长的话，就会管理关于人、物、金钱相关的店铺整体业务。'}
                  </p>
                </FadeIn.Right>
              </SmallGridAboveRight>
            </SmallGrid>
          </Layout.Container>
        </Layout.Section>
        <Layout.Section id="form">
          <form action="#">
            <ContactContainer>
              <ContactHeader>
                <FadeIn.Up>
                  <h3>
                    {this.props.lang === 'ja' && 'ご応募はこちらから '}
                    {this.props.lang === 'en' && 'Look at sticking'}
                    {this.props.lang === 'zh' && '应聘点击这里'}
                  </h3>
                </FadeIn.Up>
              </ContactHeader>
              <ContactButtonStore href="tel:0647083466">
                {this.props.lang === 'ja' && 'お電話でのご応募 '}
                {this.props.lang === 'en' && 'Look at sticking'}
                {this.props.lang === 'zh' && '电话应聘'}：06-4708-6470
                <br />（ {this.props.lang === 'ja' && '芙蓉苑　採用担当まで'}
                {this.props.lang === 'en' && 'Look at sticking'}
                {this.props.lang === 'zh' && '请洽芙蓉苑录用负责人'}）
              </ContactButtonStore>
              <ContactRow>
                <ContactTitleOdd>
                  <label htmlFor="name">
                    {this.props.lang === 'ja' && '氏名'}
                    {this.props.lang === 'en' && 'Look at sticking'}
                    {this.props.lang === 'zh' && '姓名'}
                  </label>
                </ContactTitleOdd>
                <ContactBody>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="芙蓉　太朗"
                    required=""
                    value={this.state.name}
                    onChange={e => this.setState({ name: e.target.value })}
                  />
                </ContactBody>
              </ContactRow>
              <ContactRow>
                <ContactTitleEven>
                  <label htmlFor="furigana">
                    {lang === 'ja' && 'フリガナ'}
                    {lang === 'en' &&
                      'I want to convey the true taste of authentic Sichuan cuisine'}
                    {lang === 'zh' && '片假名'}
                  </label>
                </ContactTitleEven>
                <ContactBody>
                  <input
                    type="text"
                    id="furigana"
                    name="furigana"
                    placeholder="フヨウ　タロウ"
                    required=""
                    value={this.state.furigana}
                    onChange={e => this.setState({ furigana: e.target.value })}
                  />
                </ContactBody>
              </ContactRow>
              <ContactRow>
                <ContactTitleOdd>
                  <label htmlFor="tel">
                    {lang === 'ja' && '電話番号'}
                    {lang === 'en' &&
                      'I want to convey the true taste of authentic Sichuan cuisine'}
                    {lang === 'zh' && '电话号码'}
                  </label>
                </ContactTitleOdd>
                <ContactBody>
                  <input
                    type="text"
                    id="tel"
                    name="tel"
                    placeholder="電話番号"
                    required=""
                    value={this.state.tel}
                    onChange={e => this.setState({ tel: e.target.value })}
                  />
                </ContactBody>
              </ContactRow>
              <ContactRow>
                <ContactTitleEven>
                  <label htmlFor="email">
                    {lang === 'ja' && 'メールアドレス'}
                    {lang === 'en' &&
                      'I want to convey the true taste of authentic Sichuan cuisine'}
                    {lang === 'zh' && '邮箱'}
                  </label>
                </ContactTitleEven>
                <ContactBody>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    placeholder="example@example.com"
                    required=""
                    value={this.state.email}
                    onChange={e => this.setState({ email: e.target.value })}
                  />
                </ContactBody>
              </ContactRow>
              <ContactRow>
                <ContactTitleOdd>
                  <label htmlFor="birthYear">
                    {lang === 'ja' && '生年月日'}
                    {lang === 'en' && 'Birth Day'}
                    {lang === 'zh' && '生年月日'}
                  </label>
                </ContactTitleOdd>
                <ContactBody>
                  <div className="selectWrap">
                    <select
                      name="birthYear"
                      placeholder="年"
                      required=""
                      value={this.state.birthYear}
                      onChange={e =>
                        this.setState({ birthYear: e.target.value })
                      }
                    >
                      <option value="年">年</option>
                      <option value="1950">1950</option>
                      <option value="1951">1951</option>
                      <option value="1952">1952</option>
                      <option value="1953">1953</option>
                      <option value="1954">1954</option>
                      <option value="1955">1955</option>
                      <option value="1956">1956</option>
                      <option value="1957">1957</option>
                      <option value="1958">1958</option>
                      <option value="1959">1959</option>
                      <option value="1960">1960</option>
                      <option value="1961">1961</option>
                      <option value="1962">1962</option>
                      <option value="1963">1963</option>
                      <option value="1964">1964</option>
                      <option value="1965">1965</option>
                      <option value="1966">1966</option>
                      <option value="1967">1967</option>
                      <option value="1968">1968</option>
                      <option value="1969">1969</option>
                      <option value="1970">1970</option>
                      <option value="1971">1971</option>
                      <option value="1972">1972</option>
                      <option value="1973">1973</option>
                      <option value="1974">1974</option>
                      <option value="1975">1975</option>
                      <option value="1976">1976</option>
                      <option value="1977">1977</option>
                      <option value="1978">1978</option>
                      <option value="1979">1979</option>
                      <option value="1980">1980</option>
                      <option value="1981">1981</option>
                      <option value="1982">1982</option>
                      <option value="1983">1983</option>
                      <option value="1984">1984</option>
                      <option value="1985">1985</option>
                      <option value="1986">1986</option>
                      <option value="1987">1987</option>
                      <option value="1988">1988</option>
                      <option value="1989">1989</option>
                      <option value="1990">1990</option>
                      <option value="1991">1991</option>
                      <option value="1992">1992</option>
                      <option value="1993">1993</option>
                      <option value="1994">1994</option>
                      <option value="1995">1995</option>
                      <option value="1996">1996</option>
                      <option value="1997">1997</option>
                      <option value="1998">1998</option>
                      <option value="1999">1999</option>
                      <option value="2000">2000</option>
                      <option value="2001">2001</option>
                      <option value="2002">2002</option>
                      <option value="2003">2003</option>
                      <option value="2004">2004</option>
                      <option value="2005">2005</option>
                      <option value="2006">2006</option>
                      <option value="2007">2007</option>
                      <option value="2008">2008</option>
                      <option value="2009">2009</option>
                      <option value="2010">2010</option>
                      <option value="2011">2011</option>
                      <option value="2012">2012</option>
                      <option value="2013">2013</option>
                      <option value="2014">2014</option>
                      <option value="2015">2015</option>
                      <option value="2016">2016</option>
                      <option value="2017">2017</option>
                      <option value="2018">2018</option>
                      <option value="2019">2019</option>
                    </select>
                  </div>
                  <div className="selectWrap">
                    <select
                      name="birthMonth"
                      placeholder="月"
                      required=""
                      value={this.state.birthMonth}
                      onChange={e =>
                        this.setState({ birthMonth: e.target.value })
                      }
                    >
                      <option value="月">月</option>
                      <option value="01">01</option>
                      <option value="02">02</option>
                      <option value="03">03</option>
                      <option value="04">04</option>
                      <option value="05">05</option>
                      <option value="06">06</option>
                      <option value="07">07</option>
                      <option value="08">08</option>
                      <option value="09">09</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </select>
                  </div>
                  <div className="selectWrap">
                    <select
                      name="birthDay"
                      placeholder="日"
                      required=""
                      value={this.state.birthDay}
                      onChange={e =>
                        this.setState({ birthDay: e.target.value })
                      }
                    >
                      <option value="日">日</option>
                      <option value="01">01</option>
                      <option value="02">02</option>
                      <option value="03">03</option>
                      <option value="04">04</option>
                      <option value="05">05</option>
                      <option value="06">06</option>
                      <option value="07">07</option>
                      <option value="08">08</option>
                      <option value="09">09</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                      <option value="21">21</option>
                      <option value="22">22</option>
                      <option value="23">23</option>
                      <option value="24">24</option>
                      <option value="25">25</option>
                      <option value="26">26</option>
                      <option value="27">27</option>
                      <option value="28">28</option>
                      <option value="29">29</option>
                      <option value="30">30</option>
                      <option value="31">31</option>
                    </select>
                  </div>
                </ContactBody>
                {this.state.mailSent && (
                  <Success>
                    {lang === 'ja' && 'ご応募ありがとうございました。'}
                    {lang === 'en' &&
                      'I want to convey the true taste of authentic Sichuan cuisine'}
                    {lang === 'zh' && '谢谢您的申请'}
                  </Success>
                )}
              </ContactRow>
              <ContactSubmit>
                <p>
                  {lang === 'ja' && 'メールにて折り返しご連絡いたします'}
                  {lang === 'en' &&
                    'I want to convey the true taste of authentic Sichuan cuisine'}
                  {lang === 'zh' && '通过邮件回复您'}
                </p>
                <ContactButtons>
                  <FadeIn.Up>
                    <ContactButton onClick={e => this.handleFormSubmit(e)}>
                      <p>
                        {this.props.lang === 'ja' && '送信'}
                        {this.props.lang === 'en' && 'Send'}
                        {this.props.lang === 'zh' && '发送'}
                      </p>
                    </ContactButton>
                  </FadeIn.Up>
                </ContactButtons>
              </ContactSubmit>
            </ContactContainer>
          </form>
        </Layout.Section>
      </>
    )
  }
}

export const pageQuery = graphql`
  {
    allWordpressPost {
      edges {
        node {
          id
          title
          date(formatString: "YYYY/MM/DD")
          slug
        }
      }
    }
  }
`

const mapStateToProps = state => ({
  lang: state.lang,
})

export default connect(mapStateToProps)(Recruit)

const pink = '#e3007f'
const beige = '#fadcbe'
const lightBeige = '#FEF8F2'
const brown = '#603813'
const darkBrown = '#42210B'

const Hero = styled.div`
  position: relative;
  height: 66.6666666666vw;
  max-height: 600px;
  overflow: hidden;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(/img/recruit-hero.jpg) center/cover;
  animation: 15s ${Layout.ZoomBg} infinite;
`

const HeroHeader = styled.header`
  position: absolute;
  transform: translate(-50%, -50%);
  color: #fff;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: calc(100% - 60px);
  @media (min-width: 1025px) {
    max-width: 450px;
    left: 30%;
  }
  h1 {
    line-height: 2;
    letter-spacing: 10px;
    font-size: 1.5rem;
  }
  p {
    line-height: 1.4;
    letter-spacing: 5px;
  }
`

const Grid = styled.section`
  @media (min-width: 1025px) {
    display: grid;
    grid-template-columns: 0.5fr 4fr 3fr 0.5fr;
    grid-template-rows: 2fr 2fr 0.25fr;
  }
`

const GridBodyAbove = styled.div`
  background: ${lightBeige};
  @media (min-width: 1025px) {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 3fr;
    z-index: 2;
  }
`

const GridBodyAboveTxt = styled.div`
  padding: 30px;
  color: ${brown};
  @media (min-width: 1025px) {
    padding: 0;
    grid-row: 1 / 2;
    display: grid;
    grid-template-columns: 1fr 10fr 1fr;
    grid-template-rows: 2fr 3fr 1fr;
  }
`

const GridBodyAboveTxtBox = styled.div`
  h3 {
    color: ${brown};
    &:after {
      content: '';
      display: block;
      height: 1px;
      width: 70px;
      background: ${brown};
      margin-top: 23px;
      margin-bottom: 23px;
    }
  }
  p {
    line-height: 2;
  }
  @media (min-width: 1025px) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    transform: translateX(150px);
    h3 {
      font-size: 38px;
      &:after {
        margin-top: 38px;
        margin-bottom: 38px;
      }
    }
    p {
      font-size: 18px;
    }
  }
`

const GridBodyAboveImg = styled.div`
  position: relative;
  @media (min-width: 1025px) {
    grid-row: 2 / 3;
    img {
      object-fit: cover;
      height: 100%;
    }
  }
`

const GridBodyAboveImgWrapper = styled.div`
  position: absolute;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 33.33333%,
    ${pink} 33.33333%,
    ${pink} 66.66666%,
    ${lightBeige} 66.66666%
  );
  background-position: 100%;
  background-size: 300%;
  ${({ isActive }) =>
    isActive &&
    css`
      animation: ${Layout.SlideBg} 1s ease-out 1 both;
    `}
`

const GridBodyBelow = styled.div`
  padding: 30px;
  @media (min-width: 1025px) {
    padding: 0;
    grid-column: 2 / 4;
    grid-row: 2 / 4;
  }
`

const GridBodyBelowBox = styled.div`
  border: 4px solid ${brown};
  @media (min-width: 1025px) {
    height: 100%;
    display: grid;
    grid-template-columns: 7.5fr 4fr 0.5fr;
    grid-template-rows: 1fr 4fr 1fr;
  }
`

const GridBodyBelowTxt = styled.div`
  padding: 30px;
  color: ${brown};
  h3 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 30px;
    line-height: 2;
    @media (min-width: 1025px) {
      margin-bottom: 30px;
    }
  }
  @media (min-width: 1025px) {
    padding: 15px;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    h2 {
      font-size: 52px;
      &::after {
        height: 36px;
        margin-top: -36px;
        margin-bottom: 15px;
      }
    }
    h3 {
      font-size: 18px;
      margin-bottom: 30px;
    }
    p {
      font-size: 18px;
    }
  }
`

const GridBodyBelowH2 = styled.h2`
  font-family: 'Crimson Text', serif;
  font-style: italic;
  font-size: 36px;
  &::after {
    content: '';
    display: block;
    width: 0;
    height: 23px;
    background: ${beige};
    margin-top: -23px;
    margin-bottom: 15px;
    z-index: -1;
    transition: width 1.5s ease;
  }
  @media (min-width: 1025px) {
    font-size: 52px;
    &::after {
      height: 36px;
      margin-top: -36px;
      margin-bottom: 15px;
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      &::after {
        width: 100%;
      }
    `}
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Button = styled.a`
  position: relative;
  z-index: 1;
  p {
    background: linear-gradient(90deg, ${pink} 0%, ${beige} 33.3333333333%);
    background-size: 300%;
    background-position: 0;
    position: relative;
    display: block;
    padding: 20px 30px;
    margin-top: 0;
    margin-bottom: 0;
    color: #fff;
    transition: transform 0.5s ease, box-shadow 0.5s ease;

    &:before {
      font-size: 18px;
      content: '→';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
    &:hover {
      animation: 1s ${Layout.SlideBg} 1;
      transform: translate(10px, 10px);
    }
  }
  &:after {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: ${brown};
    top: 10px;
    left: 10px;
    z-index: -1;
  }
`

const InfoHeader = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(/img/recruit-info.jpg) center/cover;
  color: #fff;
  padding: 30px;
  h2 {
    &:after {
      content: '';
      display: block;
      height: 1px;
      width: 70px;
      background: #fff;
      margin-top: 23px;
      margin-bottom: 23px;
    }
  }
  p {
    line-height: 2;
  }
  @media (min-width: 1025px) {
    padding: 50px 30px;
    h2 {
      font-size: 38px;
      &:after {
        margin-top: 38px;
        margin-bottom: 38px;
      }
    }
    p {
      font-size: 18px;
    }
  }
`

const InfoBox = styled.ul`
  display: flex;
  flex-wrap: wrap;
  color: ${brown};
  &:nth-of-type(odd) {
    background: ${lightBeige};
  }
`

const InfoDate = styled.div`
  width: 40%;
  padding: 15px;
  @media (min-width: 415px) {
    width: 20%;
  }
`

const InfoBody = styled.div`
  width: 60%;
  padding: 15px;
  @media (min-width: 415px) {
    width: 80%;
  }
`

const ChallengeHeader = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(/img/recruit-challenge.jpg) center/cover;
  color: #fff;
  padding: 30px;
  h2 {
  }
  p {
    line-height: 2;
  }
  @media (min-width: 1025px) {
    padding: 80px 30px;
    h2 {
      font-size: 38px;
    }
    p {
      font-size: 18px;
    }
  }
`

const ChallengeSubHeader = styled.div`
  color: ${brown};
  padding: 30px;
  h3 {
    &:after {
      content: '';
      display: block;
      height: 1px;
      width: 70px;
      background: ${brown};
      margin-top: 23px;
      margin-bottom: 23px;
    }
  }
  p {
    line-height: 2;
  }
  @media (min-width: 1025px) {
    padding: 50px 30px;
    h3 {
      font-size: 38px;
      &:after {
        margin-top: 38px;
        margin-bottom: 38px;
      }
    }
    p {
      font-size: 18px;
    }
  }
`

const ChallengeBox = styled.p`
  background: ${lightBeige};
  color: ${brown};
  padding: 30px;
  width: 100%;
`

const RequirementsHeader = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(/img/recruit-requirements.jpg) center/cover;
  color: #fff;
  padding: 30px;
  h2 {
  }
  p {
    line-height: 2;
  }
  @media (min-width: 1025px) {
    padding: 80px 30px;
    h2 {
      font-size: 38px;
    }
    p {
      font-size: 18px;
    }
  }
`

const SmallGrid = styled.div`
  padding: 30px;
  background: ${lightBeige};
  @media (min-width: 1025px) {
    display: grid;
    grid-template-columns: 4fr 1fr 1fr 4fr;
    grid-template-rows: 1fr 5fr 1fr;
  }
`

const SmallGridAboveLeft = styled.div`
  padding: 30px;
  background: #fff;
  color: ${brown};
  position: relative;
  z-index: 1;
  h3 {
    padding-bottom: 30px;
  }
  p {
    line-height: 2;
  }
  @media (min-width: 1025px) {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
  }
`

const SmallGridAboveRight = styled.div`
  padding: 30px;
  background: #fff;
  color: ${brown};
  position: relative;
  z-index: 1;
  h3 {
    padding-bottom: 30px;
  }
  p {
    line-height: 2;
  }
  @media (min-width: 1025px) {
    grid-column: 3 / 5;
    grid-row: 2 / 3;
  }
`

const SmallGridBelowRight = styled.div`
  position: relative;
  @media (min-width: 1025px) {
    grid-column: 2 / 5;
    grid-row: 1 / 4;
  }
`

const SmallGridBelowLeft = styled.div`
  position: relative;
  @media (min-width: 1025px) {
    grid-column: 1 / 4;
    grid-row: 1 / 4;
  }
`

const SmallGridBelowWrapper = styled.div`
  position: absolute;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 33.33333%,
    ${pink} 33.33333%,
    ${pink} 66.66666%,
    ${lightBeige} 66.66666%
  );
  background-position: 100%;
  background-size: 300%;
  ${({ isActive }) =>
    isActive &&
    css`
      animation: ${Layout.SlideBg} 1s ease-out 1 both;
    `}
`

const ContactContainer = styled(Layout.Container)`
  max-width: 960px;
`

const ContactRow = styled(Layout.Row)`
  margin-bottom: 5px;
  margin-left: 0;
  margin-right: 0;
`

const ContactHeader = styled.div`
  color: ${brown};
  padding: 30px;
  h3 {
    text-align: center;
    &:after {
      content: '';
      display: block;
      height: 1px;
      width: 70px;
      background: ${brown};
      margin-top: 23px;
      margin-bottom: 23px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  p {
    line-height: 2;
  }
  @media (min-width: 1025px) {
    padding: 50px 30px;
    h3 {
      font-size: 38px;
      &:after {
        margin-top: 38px;
        margin-bottom: 38px;
      }
    }
    p {
      font-size: 18px;
    }
  }
`

const ContactTitleOdd = styled.div`
  position: relative;
  background: ${lightBeige};
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${brown};
  padding: 15px;
  &::before {
    content: '';
    display: block;
    width: 5px;
    height: 100%;
    background: ${brown};
    left: 0;
    position: absolute;
  }
  @media (min-width: 415px) {
    width: 20%;
  }
`

const ContactTitleEven = styled.div`
  position: relative;
  background: #ececec;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${brown};
  padding: 15px;
  &::before {
    content: '';
    display: block;
    width: 5px;
    height: 100%;
    background: ${brown};
    left: 0;
    position: absolute;
  }
  @media (min-width: 415px) {
    width: 20%;
  }
`

const ContactBody = styled.div`
  padding: 5px;
  text-align: center;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    height: 100%;
    padding: 15px;
    border: 3px solid ${lightBeige};
    border-radius: 3px;
    font-size: 16px;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    height: 100%;
    padding: 15px;
    border: 3px solid ${lightBeige};
    border-radius: 3px;
    font-size: 16px;
    background: #fff;
  }
  .selectWrap {
    position: relative;
    width: 33%;
    &::after {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      border: 0px;
      border-bottom: solid 2px #000;
      border-right: solid 2px #000;
      transform: translateY(-50%) rotate(45deg);
      position: absolute;
      top: 50%;
      right: 15px;
    }
  }
  @media (min-width: 415px) {
    width: 80%;
  }
`

const ContactSubmit = styled.div`
  p {
    color: ${brown};
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
  }
`
const ContactButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ContactButton = styled.button`
  position: relative;
  z-index: 1;
  p {
    background: linear-gradient(90deg, ${pink} 0%, ${beige} 33.3333333333%);
    background-size: 300%;
    background-position: 0;
    position: relative;
    display: block;
    padding: 20px 30px;
    margin-top: 0;
    margin-bottom: 0;
    color: #fff;
    transition: transform 0.5s ease, box-shadow 0.5s ease;

    &:before {
      font-size: 18px;
      content: '→';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
    &:hover {
      animation: 1s ${Layout.SlideBg} 1;
      transform: translate(10px, 10px);
    }
  }
  &:after {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: ${brown};
    top: 10px;
    left: 10px;
    z-index: -1;
  }
`

const ContactButtonStore = styled.a`
  position: relative;
  width: 100%;
  display: block;
  padding: 20px 30px;
  color: #fff;
  white-space: nowrap;
  border: none;
  background: linear-gradient(90deg, ${pink} 0%, ${beige} 100%);
  margin-bottom: 30px;
  text-align: center;
  font-size: 13px;
  transition: transform 0.5s ease;
  @media (min-width: 415px) {
    font-size: 23px;
  }
  &:hover {
    transform: translateY(10px);
  }
`

const Success = styled.div`
  background: ${beige};
  color: ${brown};
  padding: 15px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
`
